import { t } from "@lingui/macro";
import { StackSimple, SquaresFour, Plus, DownloadSimple } from "@phosphor-icons/react";
import { ScrollArea, Tabs, TabsContent, TabsList, TabsTrigger } from "@rocket-resume/ui";
import { motion } from "framer-motion";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDialog } from "@/client/stores/dialog";
import { GridView } from "./_layouts/grid";
import { ListView } from "./_layouts/list";
import { ResumeDto } from "@rocket-resume/dto";


type Layout = "grid" | "list";

export const ResumesPage = () => {
  const [layout, setLayout] = useState<Layout>("grid");
  const { open: openResume } = useDialog<ResumeDto>("resume");
  const { open: openImport } = useDialog("import");

  return (
    <>
      <Helmet>
        <title>
          {t`Resumes`} - {t`Rocket Resume`}
        </title>
      </Helmet>

      <Tabs
        value={layout}
        className="space-y-8 mt-6"
        onValueChange={(value) => {
          setLayout(value as Layout);
        }}
      >
        <div className="flex gap-6 items-center justify-between">
          <motion.h1
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            className="text-3xl font-bold tracking-tight"
          >
            {t`Resumes`}
          </motion.h1>

          <div className="flex items-center gap-2 lg:gap-4">
            <div className="flex items-center gap-1 lg:gap-2 cursor-pointer" onClick={() => {openResume("create")}}> 
              <Plus size={20} />
              <span className="ml-2 hidden md:block">Create a new</span>
            </div>
            <div className="flex items-center gap-1 lg:gap-2 bg-[#FFEB00] h-[3rem] text-[#000000] text-sm font-medium transition-all whitespace-nowrap rounded-sm px-2 lg:px-4 py-1 cursor-pointer" onClick={() => {openImport("create")}}>
              <DownloadSimple size={20}/>
              <span className="lg:ml-2 hidden md:block">{t`Import an existing resume`}</span>
            </div>

            <TabsList>
              <TabsTrigger value="grid" className="size-14 p-0 sm:h-[3.25rem] sm:w-auto sm:px-4">
                <SquaresFour size={24}/>
                <span className="ml-2 hidden md:block">Grid View</span>
              </TabsTrigger>
              <TabsTrigger value="list" className="size-8 p-0 sm:h-[3.25rem] sm:w-auto sm:px-4">
                <StackSimple size={24}/>
                <span className="ml-2 hidden md:block">Layer View</span>
              </TabsTrigger>
            </TabsList>
          </div>
        </div>
          <TabsContent value="grid">
            <GridView />
          </TabsContent>
          <TabsContent value="list">
            <ListView />
          </TabsContent>
      </Tabs>
    </>
  );
};
